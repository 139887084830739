// PROJECT IMPORTS
import { Button } from "../forms";

// TYPES
interface ModalProps {
    title?: string;
    content?: string;
    doneText?: string;
    cancelText?: string;
    children?: React.ReactNode;
    visible: boolean;
    onCancelClick?: any;
    onDoneClick?: any;
    isLoading?: boolean;
    form?: string;
    type?: "submit" | "button" | "reset" | undefined;
}

/* ============================== DELETE MODEL ============================== */

const Modal = (props: ModalProps) => {
    const {
        title,
        content,
        doneText = "Done",
        cancelText = "Cancel",
        children,
        visible = false,
        isLoading = false,
        onCancelClick,
        onDoneClick,
        form,
        type = "button",
    } = props;

    return (
        <>
            <div
                id="popup-modal"
                tabIndex={-1}
                className={`fixed top-0 left-0 right-0 z-[60] px-4 overflow-x-hidden overflow-y-auto md:inset-0 h-[100%] max-h-full bg-[#18508D] bg-opacity-60 ${!visible && "hidden"
                    }`}
                onClick={onCancelClick}
            >
                <div className="w-full h-full relative flex justify-center">
                    <div className="absolute max-w-[750px] top-[80px] w-full max-h-full">
                        <div
                            className="bg-white rounded-lg shadow"
                            onClick={(e: any) => e.stopPropagation()}
                        >
                           {title &&  <div className="flex items-start drop-shadow bg-white rounded-t-lg">
                                <div className="pl-4 py-2 w-full">
                                    <p className="font-bold text-sm">{title}</p>
                                </div>
                            </div>}
                            <div className="w-full py-4">
                                {children ? (
                                    children
                                ) : (
                                    <p className="mb-10 text-lg font-normal text-txt-primary px-4">
                                        {content}
                                    </p>
                                )}
                            </div>
                            <div className="py-3 w-full flex items-center justify-end border-t border-dark-700 px-5">
                                <div className="flex gap-4">
                                    <Button
                                        className="py-1.5 px-4"
                                        text={cancelText}
                                        styles="dark-outline"
                                        onClick={onCancelClick}
                                    />
                                    <Button
                                        type={type}
                                        form={form}
                                        isLoading={isLoading}
                                        disabled={isLoading}
                                        className="py-2 px-4"
                                        text={doneText}
                                        styles="primary"
                                        onClick={onDoneClick}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default Modal;
