import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    faChevronLeft,
    faChevronRight,
} from "@fortawesome/free-solid-svg-icons";

// THIRD-PARTY IMPORT
import ReactPaginate from "react-paginate";

// TYPES
interface PaginationProps {
    className?: string;
    page?: number;
    totalCount?: number;
    limit?: number;
    isAdvance?: boolean;
    onPageClick?: any;
    classNameMain?: string;
    count: number;
}

/* ============================== PAGINATION ============================== */

const Pagination = (props: PaginationProps) => {
    const {
        className,
        page = 1,
        totalCount = 0,
        limit = 10,
        isAdvance,
        onPageClick,
        classNameMain,
        count = 0,
    } = props;
    const totalPage = Math.ceil(totalCount / limit);

    const handlePageClick = (event: any) => {
        onPageClick(event.selected + 1);
    };

    return (
        <div
            className={`${totalCount === 0 && "!justify-center"
                } w-full bg-white py-2 flex justify-between items-center px-6 rounded-b-lg border-[#EDEDED] border`}
        >
            <div className={`${classNameMain} flex items-center`}>
                <ReactPaginate
                    containerClassName={`flex gap-1 items-center ${className}`}
                    pageClassName={`px-2 py-1 rounded-md text-txt-primary font-normal text-xs border-[#ECEEF5] border`}
                    activeClassName="border-primary border !text-primary text-xs"
                    breakLabel=" . . "
                    forcePage={page - 1}
                    breakClassName="text-txt-primary font-normal px-2 py-1"
                    nextLabel={
                        <FontAwesomeIcon
                            className="cursor-pointer px-2 py-1.5 border-[#ECEEF5] border rounded-md"
                            color="#474747"
                            icon={faChevronRight}
                        />
                    }
                    nextClassName={`${page === totalPage ? "text-txt-secondary" : "text-txt-primary"
                        } smd:pl-1 pl-2 text-xs`}
                    onPageChange={handlePageClick}
                    pageRangeDisplayed={4}
                    pageCount={isAdvance ? 1 : totalPage}
                    previousLabel={
                        <FontAwesomeIcon
                            className="cursor-pointer px-2 py-1.5 border-[#ECEEF5] border rounded-md"
                            color="#474747"
                            icon={faChevronLeft}
                        />
                    }
                    previousClassName={`${page === 1 ? "text-txt-secondary" : "text-txt-primary"
                        } smd:pr-1 pr-2 text-xs`}
                    renderOnZeroPageCount={null}
                />
            </div>
            {totalCount > 0 ? (
                <div>
                    <div className="rounded-lg border border-[#ECEEF5] px-2 py-1">
                        <label className="text-xs mr-1 font-bold">{count || 0}</label>/
                        <label className="text-xs ml-1">{totalCount || 0}</label>
                    </div>
                </div>
            ) : null}
        </div>
    );
};

export default Pagination;
