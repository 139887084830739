// PROJECT IMPORTS
import ConfirmationModal from "./ConfirmationModal";

// TYPES
interface MigrationModalProps {
    visible: boolean;
    isLoading?: boolean;
    onCancelClick?: any;
    onDoneClick?: any;
}

/* ============================== DELETE MODEL ============================== */

const MigrationModal = (props: MigrationModalProps) => {
    const {
        visible = false,
        isLoading = false,
        onCancelClick,
        onDoneClick,
    } = props;

    return (
        <>
            <ConfirmationModal
                isLoading={isLoading}
                visible={visible}
                onCancelClick={onCancelClick}
                onDoneClick={onDoneClick}
                doneText="Migrate"
                cancelText="Don't migrate"
                title="SecureFax"
                infoIcon={false}
                className="!max-w-[430px] !top-[calc(50%-150px)]"
            >
                <div>
                    <p className="text-sm text-[#8083A3]">
                        We see you currently have a SecureFax account. <span className="!text-primary cursor-pointer" onClick={onDoneClick}>Click here</span> to migrate
                        your SecureFax account to OneCloud Go.
                    </p>
                    <p className="pt-4 pb-3 text-sm text-black font-normal">
                        <b>Note: </b>This action is irreversible and will move all your fax
                        history, phone numbers, and settings into OneCloud Go, after which
                        you will no longer have access to the SecureFax portal but will have
                        the same functionality in OneCloud Go. This action will not impact
                        billing or contracts.
                    </p>
                </div>
            </ConfirmationModal>
        </>
    );
};

export default MigrationModal;
