import React from "react";
import {
  Button,
  CheckBox,
  Input,
  // PhoneNumberInput,
  Select,
} from "components/ui-component/forms";
import { AddCoverPageModal } from "components/ui-component/modals";
import { useNavigate } from "react-router-dom";
import { useFormik } from "formik";
import * as Yup from "yup";

import { useAppDispatch } from "redux/hooks";
import { sendFax } from "redux/slice/faxSlice";
import { Danger, Success } from "components/Toast/toasterService";
import PdfViewer from "components/ui-component/pdf-viewer/PdfViewer";

import add from "assets/icons/add.svg";
import trash from "assets/icons/trash-blue.svg";
import closeSquare from "assets/icons/close-square.svg";
import close from "assets/icons/gray-icon/close-circle.svg";
import doc from "assets/icons/gray-icon/document.svg";
import { useAuth0 } from "@auth0/auth0-react";
import { useNumber } from "redux/slice/commanSlice";

const ComposeFax = () => {
  const { user } = useAuth0();
  const number = useNumber();

  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const initialData = {
    cover_to_name: "",
    to_number: "",
  };
  // const [addCover, setAddCover] = React.useState<boolean>(false);
  const [isUpload, setIsUpload] = React.useState<boolean>(false);
  const [openModal, setOpenModal] = React.useState<boolean>(false);
  const [recipient, setRecipient] = React.useState<any>([initialData]);

  const initialValues: any = {
    username: user?.email,
    from_number: number,
    to_number: "",
    notes: "",
    fax_file: [],
    cover_subject: "",
    cover_body: "",
    cover_to_name: "",
    cover_from_name: "",
    addCover: false,
  };

  const validationSchema = Yup.object<any>({
    from_number: Yup.string().required("Please enter number"),
    notes: Yup.string().when("addCover", {
      is: true,
      then: (e) => e.required("Please enter notes"),
    }),
    cover_subject: Yup.string().when("addCover", {
      is: true,
      then: (e) => e.required("Please enter subject"),
    }),
    cover_body: Yup.string().when("addCover", {
      is: true,
      then: (e) => e.required("Please enter message"),
    }),
    cover_from_name: Yup.string().required("Please enter name"),
  });

  const onSubmit = async (values: any) => {
    if (!values.fax_file.length) {
      Danger("Please Seleact any file");
      return;
    }
    try {
      const {
        username,
        from_number,
        // to_number,
        notes,
        fax_file,
        cover_subject,
        cover_body,
        // cover_to_name,
        cover_from_name,
      } = values;
      const payload = new FormData();
      payload.append("username", username);
      payload.append("from_number", from_number);
      payload.append("notes", notes);
      payload.append("cover_subject", cover_subject);
      payload.append("cover_body", cover_body);
      payload.append("cover_from_name", cover_from_name);
      for (let i = 0; i < fax_file.length; i++) {
        payload.append("fax_file", fax_file[i]);
      }
      let res: any;
      for (let index = 0; index < recipient.length; index++) {
        payload.append("to_number", recipient[index]?.to_number);
        payload.append("cover_to_name", recipient[index]?.cover_to_name);
        if (recipient[index]?.to_number && recipient[index]?.cover_to_name) {
          res = await dispatch(sendFax(payload)).unwrap();
        }
      }
      if (res && res.status === "Accepted") {
        Success("Fax sent successfully");
        navigate(-1);
      } else {
        Danger("Something went wrong");
      }
    } catch (error: any) {
      console.log("Sent fax err:---->", error?.message);
    }
  };

  const {
    values,
    touched,
    errors,
    handleBlur,
    handleChange,
    handleSubmit,
    setFieldValue,
    setValues,
  } = useFormik({
    initialValues,
    validationSchema,
    onSubmit,
  });

  const onSelectFile = async (e: any) => {
    setValues({ ...values, fax_file: e.target.files });
  };

  const addMore = () => {
    setRecipient([...recipient, initialData]);
  };

  const handleChangeData = (key: string, val: string, idx: number) => {
    let newData = [...recipient];
    newData[idx] = { ...newData[idx], [key]: val };
    setRecipient(newData);
  };

  const deleteRecipient = (idx: number) => {
    let data = [...recipient];
    data.splice(idx, 1);
    setRecipient(data);
  };

  const removeFile = (idx: number) => {
    let data = [...values?.fax_file];
    data.splice(idx, 1);
    setValues({
      ...values,
      fax_file: data,
    });
  };

  return (
    <>
      <div className="p-4 w-full min-h-[calc(100vh-55px)]">
        <div className="bg-white p-4 rounded-md drop-shadow-md w-full h-full">
          <form onSubmit={handleSubmit}>
            <div className="pb-2 border-b-2 border-dark-800">
              <label className="text-heading font-bold text-base">
                Compose a Fax
              </label>
            </div>
            <div className="min-h-[calc(100vh-200px)] py-4 flex smd:flex-col">
              <div className="w-[70%] border-r smd:border-0 border-dark-800 pr-2 smd:p-0 smd:w-full">
                <div className="pb-4 border-b border-dark-800">
                  <label className="text-heading text-sm font-semibold">
                    Sender
                  </label>
                  <div className="grid grid-cols-2 smd:pr-0 pr-24 gap-4 items-start">
                    <Input
                      label="Name"
                      placeholder="Enter your name"
                      name="cover_from_name"
                      value={values.cover_from_name}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      errors={errors}
                      touched={touched}
                    />
                    <Input
                      label="Fax Number"
                      placeholder="Enter fax number"
                      name="from_number"
                      readOnly
                      value={values.from_number}
                      onChange={(e: any) => {
                        let value = e.target.value;
                        let val = value.replace(/[^0-9*#+]+/g, "");
                        setFieldValue("from_number", val);
                      }}
                      onBlur={handleBlur}
                      errors={errors}
                      touched={touched}
                    />
                  </div>
                </div>
                <div className="py-4 border-b border-dark-800 smd:pr-2 pr-11">
                  <div className="bg-[#f8fcff] p-4">
                    <label className="text-heading text-sm font-semibold">
                      Recipient
                    </label>
                    <div className="grid grid-cols-2 smd:pr-4 pr-10 gap-4">
                      <div className="text-xs font-semibold pb-1.5 flex items-center">
                        <p>Name</p>
                      </div>
                      <div className="text-xs font-semibold pb-1.5 flex items-center">
                        <p>Fax Number</p>
                      </div>
                    </div>
                    {recipient?.map((val: any, index: number, array: any[]) => {
                      return (
                        <div key={index}>
                          <div className="grid grid-cols-2 smd:pr-4 pr-10 gap-4 pt-2">
                            <Input
                              label="Name"
                              isShowLabel={false}
                              className="!bg-transparent"
                              placeholder="Enter recipient name"
                              name="cover_to_name"
                              value={val.cover_to_name}
                              onChange={(e: any) =>
                                handleChangeData(
                                  "cover_to_name",
                                  e.target.value,
                                  index
                                )
                              }
                              onBlur={handleBlur}
                              errors={errors}
                              touched={touched}
                            />
                            <div className="relative">
                              <Input
                                label="To Number"
                                placeholder="Enter to number"
                                name="to_number"
                                isShowLabel={false}
                                value={val.to_number}
                                onChange={(e: any) => {
                                  let value = e.target.value;
                                  let vals = value.replace(/[^0-9*#+]+/g, "");
                                  handleChangeData("to_number", vals, index);
                                }}
                                onBlur={handleBlur}
                                errors={errors}
                                touched={touched}
                              />
                              <img
                                src={closeSquare}
                                alt="delete"
                                height="20px"
                                width="20px"
                                className={`cursor-pointer absolute -right-8 top-1.5 ${
                                  index === 0 && "hidden"
                                }`}
                                onClick={() => deleteRecipient(index)}
                              />
                            </div>
                          </div>
                          <div
                            className={`py-2 ${
                              array?.length - 1 !== index && "hidden"
                            }`}
                          >
                            <div className="flex">
                              <div
                                className="cursor-pointer flex gap-2 items-center"
                                onClick={() => {
                                  if (!(val?.cover_to_name || val?.to_number)) {
                                    Danger("Please fill details");
                                  } else {
                                    addMore();
                                  }
                                }}
                              >
                                <img
                                  src={add}
                                  alt="add"
                                  height="16px"
                                  width="16px"
                                />
                                <span className="text-primary text-xs font-bold">
                                  Add more
                                </span>
                              </div>
                            </div>
                          </div>
                        </div>
                      );
                    })}
                  </div>
                </div>
                <div className="py-4 border-b border-dark-800">
                  <label className="text-heading text-sm font-semibold">
                    Cover Page
                  </label>
                  <div className="grid grid-cols-2 pt-2">
                    <CheckBox
                      label="Add Cover Page"
                      labelClassName="!text-xs"
                      value={values?.addCover}
                      checked={values?.addCover}
                      onChange={(e: any) =>
                        setFieldValue("addCover", e.target.checked)
                      }
                    />
                  </div>
                  {values?.addCover ? (
                    <div className="flex gap-5 items-center py-4">
                      <div className="flex items-center">
                        <input
                          className="w-4 h-4 text-primary bg-gray-100 border-gray-300 focus:ring-primary accent-primary"
                          type="radio"
                          name="default radio"
                          checked={!isUpload}
                          onChange={(e: any) => {
                            setIsUpload(!e.target.checked);
                          }}
                        />
                        <label className="ml-2 text-xs font-medium text-gray-900">
                          Auto Generate
                        </label>
                      </div>
                      <div className="flex items-center">
                        <input
                          className="w-4 h-4 text-primary bg-gray-100 border-gray-300 focus:ring-primary accent-primary"
                          type="radio"
                          name="default radio"
                          checked={isUpload}
                          onChange={(e: any) => {
                            setIsUpload(e.target.checked);
                          }}
                        />
                        <label className="ml-2 text-xs font-medium text-gray-900">
                          Upload
                        </label>
                      </div>
                    </div>
                  ) : null}
                  {values?.addCover && isUpload ? (
                    <div className="py-4">
                      <div className="smd:pr-0 pr-24">
                        <Select
                          height="35px"
                          label="Select Cover Page"
                          name="coverPage"
                          options={[]}
                          className="col-span-3"
                          placeholder="Select Cover Page"
                          onBlur={handleBlur}
                          errors={errors}
                          touched={touched}
                        />
                      </div>
                      <div className={`pt-2 flex gap-3 items-center`}>
                        <div
                          className="cursor-pointer flex gap-2 items-center"
                          onClick={() => {
                            setOpenModal(true);
                          }}
                        >
                          <img src={add} alt="add" height="16px" width="16px" />
                          <span className="text-primary text-xs font-bold">
                            Add
                          </span>
                        </div>
                        <div className="cursor-pointer flex gap-2 items-center">
                          <img
                            src={trash}
                            alt="add"
                            height="16px"
                            width="16px"
                          />
                          <span className="text-primary text-xs font-bold">
                            Delete
                          </span>
                        </div>
                      </div>
                    </div>
                  ) : (
                    <div className="grid grid-cols-2 smd:pr-0 pr-24 gap-4 items-start py-4">
                      <Input
                        label="Subject"
                        placeholder="Enter subject name"
                        name="cover_subject"
                        value={values?.cover_subject}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        errors={errors}
                        touched={touched}
                      />
                      <Input
                        label="Message"
                        placeholder="Enter message"
                        name="cover_body"
                        value={values?.cover_body}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        errors={errors}
                        touched={touched}
                      />
                    </div>
                  )}
                </div>
                <div className="py-4 border-b border-dark-800 smd:pr-0 pr-24">
                  <div className="pt-2">
                    <Input
                      label="Notes"
                      placeholder="Enter a note"
                      name="notes"
                      value={values?.notes}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      errors={errors}
                      touched={touched}
                    />
                  </div>
                </div>
                <div className="py-4 border-b border-dark-800">
                  <label className="text-heading text-sm font-semibold">
                    Upload Document
                  </label>
                  <p className="text-txt-primary text-xs max-w-[60%] pb-2 smd:max-w-[90%]">
                    {`Support file types (Max 5 Files with 25 MB total): PDF, TIF, TIFF, Microsoft Word (.doc, .docx). If needed, compress your pdf here.`}
                  </p>
                  <div className="flex flex-col items-start gap-1">
                    <label className="bg-primary text-xs px-3 py-1.5 text-white rounded-md font-bold cursor-pointer">
                      <input
                        type="file"
                        className="hidden"
                        accept="application/pdf,application/vnd.ms-excel"
                        multiple
                        onChange={(e: any) => {
                          if ([...e.target.files].length) {
                            onSelectFile(e);
                          }
                        }}
                      />
                      Custom Upload
                    </label>
                    {values?.fax_file && [...values?.fax_file]?.length ? (
                      <div className="pt-2 flex gap-4 items-center flex-wrap">
                        {[...values?.fax_file]?.map((val: any, idx: number) => {
                          return (
                            <div
                              className="w-[110px] flex items-center select-none justify-between"
                              key={idx}
                            >
                              <img src={doc} alt="doc" />
                              <p className="max-w-[65px] text-ellipsis overflow-hidden whitespace-nowrap text-xs">
                                {val.name}
                              </p>
                              <img
                                src={close}
                                alt="close"
                                className="cursor-pointer"
                                onClick={() => removeFile(idx)}
                              />
                            </div>
                          );
                        })}
                      </div>
                    ) : null}
                  </div>
                </div>
              </div>
              <div className="w-[30%] smd:w-full smd:pt-4">
                <div className="px-4 smd:px-0">
                  {values.fax_file?.length ? (
                    <PdfViewer selectFile={values.fax_file} />
                  ) : (
                    <div className="bg-[#f7fafd] h-[calc(100vh-190px)] flex items-center justify-center">
                      <label className="text-xl font-bold text-[#E0E2EB]">
                        No preview available
                      </label>
                    </div>
                  )}
                </div>
              </div>
            </div>
            <div className="pt-2 border-t-2 border-dark-800 ">
              <div className="flex gap-2 justify-end items-center">
                <Button
                  text="Cancel"
                  styles="secondary-outline"
                  onClick={() => navigate(-1)}
                />
                <Button text="Send" className="!py-2" type="submit" />
              </div>
            </div>
          </form>
        </div>
      </div>
      <AddCoverPageModal
        visible={openModal}
        onCancelClick={() => {
          setOpenModal(false);
        }}
      />
    </>
  );
};

export default ComposeFax;
