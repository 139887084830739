// PROJECT IMPORTS
import DataTable from "react-data-table-component";
import Pagination from "./Pagination";

import nodata from "assets/images/no-data.svg";

import { tableCustomStyle, tableCustomStyles } from "components/fax/mock";
import Loader from "../loader/Loader";

interface TableProps {
    columns: any[];
    data: any;
    pagination?: boolean;
    page?: number;
    totalCount?: number;
    limit?: number;
    onPageClick?: any;
    isLoading: boolean;
}

/* ============================== TABLE ============================== */

const Table = (props: TableProps) => {
    const {
        columns,
        data,
        pagination = true,
        page,
        totalCount,
        limit = 10,
        onPageClick,
        isLoading,
    } = props;

    const noDataComponent = () => {
        return (
            <>
                <div className="flex flex-col items-center gap-2">
                    <img src={nodata} alt="no data" height={100} width={100} />
                    <label className="text-base font-bold text-black">
                        No Faxes Found
                    </label>
                </div>
            </>
        );
    };

    return (
        <>
            {isLoading ? (
                <div className="bg-white rounded-lg h-[calc(100vh-160px)] smd:h-[calc(100vh-215px)]">
                    <Loader background="transparent" />
                </div>
            ) : (
                <div className="relative bg-white rounded-lg h-[calc(100vh-160px)] smd:h-[calc(100vh-215px)]">
                    <DataTable
                        className={`!rounded-[unset] ${data?.length === 0 && "!border-none"
                            }`}
                        fixedHeader
                        columns={columns}
                        data={data?.length ? data : []}
                        striped
                        customStyles={
                            data?.length > 0 ? tableCustomStyles : tableCustomStyle
                        }
                        noDataComponent={noDataComponent()}
                        responsive
                    />
                    {pagination && data?.length > 0 ? (
                        <div className="w-full absolute bottom-0">
                            <Pagination
                                page={page}
                                limit={limit}
                                totalCount={totalCount}
                                onPageClick={onPageClick}
                                isAdvance={limit < data?.length}
                                count={data?.length}
                            />
                        </div>
                    ) : null}
                </div>
            )}
        </>
    );
};

export default Table;
