import React, { useEffect } from "react";

import {
    faEllipsisVertical,
    faRefresh,
    faSearch,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button, CheckBox, Input } from "components/ui-component/forms";
import { useNavigate } from "react-router-dom";

import download from "assets/icons/gray-icon/download.svg";
import edit from "assets/icons/gray-icon/edit.svg";
import send from "assets/icons/gray-icon/arrow-up.svg";
import trash from "assets/icons/trash.svg";
import lock from "assets/icons/lock.svg";
import {
    downloadFax,
    resedFax,
    useInCount,
    useSentCount,
    useSentListDetails,
    useUserDetails,
} from "redux/slice/faxSlice";
import { useAppDispatch } from "redux/hooks";
import Table from "components/ui-component/Table/Table";
import { Danger, Success } from "components/Toast/toasterService";
import { format } from "date-fns";
import calendar from "assets/icons/calendar.svg";
import {
    setCustomeRange,
    setDeleteHide,
    setIsCustomeRange,
    setSentType,
    useCustomeRange,
    useHideDelete,
    useIsCustomeRange,
    useSentType,
    useStatusfilter,
} from "redux/slice/commanSlice";
import { DownloadFile } from "components/Helper/DownloadFile";
import Options from "./Options";

interface SentFaxProps {
    filter: any;
    setFilter: any;
    setDeleteId: any;
    setSentFaxData: any;
    setIsWarn: any;
    isLoading: boolean;
    setOpenDate: any;
}

const SentFax = (props: SentFaxProps) => {
    const {
        setDeleteId,
        setSentFaxData,
        setFilter,
        filter,
        setIsWarn,
        isLoading,
        setOpenDate,
    } = props;

    const navigation = useNavigate();
    const sentFaxList = useSentListDetails();
    const sentType = useSentType();
    const userDetails = useUserDetails();
    const inCount: number = useInCount();
    const sentCount: number = useSentCount();
    const status_filter = useStatusfilter();
    const hide_deleted = useHideDelete();
    const customRange = useCustomeRange();
    const isCustomRange = useIsCustomeRange();

    const [option, setOption] = React.useState<any>(null);
    const [open, setOpen] = React.useState<boolean>(false);
    const [search, setSearch] = React.useState<boolean>(
        filter.search_query === "" ? false : true
    );

    useEffect(() => {
        setFilter({
            ...filter,
            status_filter: status_filter,
            page: "1",
            per_page: "10",
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [status_filter]);

    const List = [
        "All",
        "Today",
        "Yesterday",
        "Last 7 Days",
        "Last 30 Days",
        "Custom Range",
    ];

    const dispatch = useAppDispatch();

    const onDownloadFax = async (id: string, path: string) => {
        try {
            let data = path.split("/").slice(-2)[0];
            let nData = data + "/" + id;
            const res: any = await dispatch(downloadFax(nData)).unwrap();
            DownloadFile(res, id);
        } catch (error: any) {
            console.log("fax download err: -->", error?.message);
        }
    };

    const onResendFax = async (id: string) => {
        try {
            const res: any = await dispatch(resedFax(id)).unwrap();
            if (res && res.status === "Resend Accepted") {
                Success("Fax resended successfully");
                setFilter({ ...filter });
            } else {
                Danger("Something went wrong");
            }
        } catch (error: any) {
            console.log("fax resend err: --->", error?.message);
        }
    };

    React.useEffect(() => {
        onSetSearchDate();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [sentType]);

    const onSetSearchDate = () => {
        switch (sentType) {
            case "All":
                setFilter({
                    ...filter,
                    page: "1",
                    per_page: "10",
                    start_date: undefined,
                    end_date: undefined,
                });
                break;
            case "Today":
                setFilter({
                    ...filter,
                    page: "1",
                    per_page: "10",
                    start_date: format(
                        new Date().setHours(0, 0, 1),
                        "yyyy-MM-dd HH:mm:ss"
                    ),
                    end_date: format(
                        new Date().setHours(23, 59, 59),
                        "yyyy-MM-dd HH:mm:ss"
                    ),
                });
                break;
            case "Yesterday":
                setFilter({
                    ...filter,
                    page: "1",
                    per_page: "10",
                    start_date: format(
                        new Date(Date.now() - 1 * 24 * 60 * 60 * 1000).setHours(0, 0, 1),
                        "yyyy-MM-dd HH:mm:ss"
                    ),
                    end_date: format(
                        new Date(Date.now() - 1 * 24 * 60 * 60 * 1000).setHours(23, 59, 59),
                        "yyyy-MM-dd HH:mm:ss"
                    ),
                });
                break;
            case "Last 7 Days":
                setFilter({
                    ...filter,
                    page: "1",
                    per_page: "10",
                    start_date: format(
                        new Date(Date.now() - 7 * 24 * 60 * 60 * 1000).setHours(0, 0, 1),
                        "yyyy-MM-dd HH:mm:ss"
                    ),
                    end_date: format(
                        new Date().setHours(23, 59, 59),
                        "yyyy-MM-dd HH:mm:ss"
                    ),
                });
                break;
            case "Last 30 Days":
                setFilter({
                    ...filter,
                    page: "1",
                    per_page: "10",
                    start_date: format(
                        new Date(Date.now() - 30 * 24 * 60 * 60 * 1000).setHours(0, 0, 1),
                        "yyyy-MM-dd HH:mm:ss"
                    ),
                    end_date: format(
                        new Date().setHours(23, 59, 59),
                        "yyyy-MM-dd HH:mm:ss"
                    ),
                });
                break;

            case "Custom Range":
                if (isCustomRange) {
                    setFilter({
                        ...filter,
                        page: "1",
                        per_page: "10",
                        start_date: format(
                            new Date(customRange?.start_date).setHours(0, 0, 1),
                            "yyyy-MM-dd HH:mm:ss"
                        ),
                        end_date: format(
                            new Date(customRange?.end_date).setHours(23, 59, 59),
                            "yyyy-MM-dd HH:mm:ss"
                        ),
                    });
                }
                break;

            default:
                break;
        }
    };

    const columns = [
        {
            name: "From Number",
            selector: (row: any) => row.from_number,
            cell: (row: any) => {
                return (
                    <div className="flex gap-3 items-center">
                        <label>{row.from_number}</label>
                        <img
                            className="cursor-pointer"
                            src={lock}
                            alt="lock"
                            title="To ensure the utmost security and confidentiality, all faxes are subjected to encryption before transmission"
                        />
                    </div>
                );
            },
            sortable: true,
        },
        {
            name: "To Number",
            selector: (row: any) => row.to_number,
            sortable: true,
        },
        {
            name: "Date",
            selector: (row: any) => row.created_on,
            sortable: true,
        },
        {
            name: "Status",
            cell: (row: any) => {
                return (
                    <div className="flex gap-2 items-center">
                        <div
                            className={`${row?.status === "sending"
                                    ? "bg-primary"
                                    : row?.status === "delivered"
                                        ? "bg-primary-green"
                                        : row?.status === "deleted"
                                            ? "bg-layout"
                                            : "bg-[#FF455C]"
                                } rounded-3xl text-white px-2 py-1 text-xs`}
                        >
                            <label className="capitalize">{row?.status}</label>
                        </div>
                        {row?.status === "failed" && (
                            <FontAwesomeIcon
                                className="cursor-pointer"
                                color="#38A6DE"
                                icon={faRefresh}
                                title="Retry"
                                onClick={() => {
                                    onResendFax(row?.uuid);
                                }}
                            />
                        )}
                    </div>
                );
            },
            selector: (row: any) => row.viewed,
            sortable: true,
        },
        {
            name: "Notes",
            selector: (row: any) => row.notes,
            sortable: true,
        },
        {
            name: "",
            cell: (row: any, index: number) => {
                return (
                    <div className="relative" onClick={(e: any) => e.stopPropagation()}>
                        <FontAwesomeIcon
                            color="#698db5"
                            icon={faEllipsisVertical}
                            className="cursor-pointer"
                            onMouseEnter={() => {
                                setOption(index);
                            }}
                        />
                        <div
                            className={`absolute top-4 left-[-100px] bg-white rounded-md drop-shadow-md py-1 w-[120px] z-[2] flex flex-col ${index === option ? "block" : "hidden"
                                }`}
                        >
                            <div
                                className="flex items-center gap-3 py-1 px-3 cursor-pointer border-b border-dark-800"
                                onClick={() => {
                                    setOption(null);
                                    onDownloadFax(row?.uuid, row?.download_link);
                                }}
                            >
                                <img src={download} alt="download" />
                                <span className="text-xs font-semibold text-black">
                                    Download
                                </span>
                            </div>
                            <div
                                className="flex items-center gap-3 py-1 px-3 cursor-pointer border-b border-dark-800"
                                onClick={() => {
                                    setSentFaxData(row);
                                    setOption(null);
                                }}
                            >
                                <img src={edit} alt="edit" />
                                <span className="text-xs font-semibold text-black">Edit</span>
                            </div>
                            <div
                                className={`flex items-center gap-3 py-1 px-1.5 cursor-pointer ${row?.status !== "deleted" && "border-b border-dark-800"
                                    }`}
                                onClick={() => {
                                    onResendFax(row?.uuid);
                                    setOption(null);
                                }}
                            >
                                <img src={send} alt="send" />
                                <span className="text-xs font-semibold text-black">Resend</span>
                            </div>
                            {row?.status !== "deleted" && (
                                <div
                                    className="flex items-center gap-3 py-1 px-3 cursor-pointer"
                                    onClick={() => {
                                        setOption(null);
                                        setDeleteId(row?.uuid);
                                    }}
                                >
                                    <img src={trash} alt="trash" width="14px" height="14px" />
                                    <span className="text-xs font-semibold text-error">
                                        Delete
                                    </span>
                                </div>
                            )}
                        </div>
                    </div>
                );
            },
        },
    ];

    return (
        <>
            <div
                onClick={() => {
                    setOption(null);
                    setOpen(false);
                }}
            >
                <div className="flex justify-between pb-2 px-1 smd:flex-col smd:gap-3">
                    <div className="flex gap-4 items-center smd:flex-wrap">
                        <div className="flex items-center gap-2">
                            {sentFaxList?.total_faxes > 0 ? (
                                <label className="text-sm font-bold text-heading">{`Sent Faxes (${sentFaxList?.total_faxes || 0
                                    })`}</label>
                            ) : (
                                <label className="text-sm font-bold text-heading">
                                    Sent Faxes
                                </label>
                            )}
                            <FontAwesomeIcon
                                className="cursor-pointer"
                                color="#38a6de"
                                icon={faRefresh}
                                onClick={() => setFilter({ ...filter })}
                            />
                        </div>
                        <div className="relative" onClick={(e: any) => e.stopPropagation()}>
                            <div
                                className="flex gap-2 items-center bg-[#ECEEF5] rounded-2xl px-2 py-1"
                                onClick={() => setOpen(!open)}
                            >
                                <img src={calendar} alt="calendar" />
                                <label className="text-xs font-semibold text-black">
                                    {sentType === "Custom Range"
                                        ? customRange?.start_date + " - " + customRange?.end_date
                                        : sentType}
                                </label>
                            </div>
                            <div
                                className={`absolute top-8 left-0 bg-white rounded-md drop-shadow-md py-1 w-[120px] z-[2] flex flex-col ${open ? "block" : "hidden"
                                    }`}
                            >
                                {List?.map((val: any, index: number) => {
                                    return (
                                        <div
                                            key={index}
                                            className={`flex items-center gap-3 py-1 px-3 cursor-pointer ${val === "Custom Range"
                                                    ? "!text-primary"
                                                    : "border-b border-dark-800 text-black"
                                                }`}
                                            onClick={() => {
                                                setOpen(false);
                                                val === "Custom Range" &&
                                                    dispatch(
                                                        setCustomeRange({
                                                            start_date: format(
                                                                new Date().setHours(0, 0, 1),
                                                                "yyyy-MM-dd HH:mm:ss"
                                                            ),
                                                            end_date: format(
                                                                new Date().setHours(23, 59, 59),
                                                                "yyyy-MM-dd HH:mm:ss"
                                                            ),
                                                        })
                                                    );
                                                dispatch(setSentType(val));
                                                val === "Custom Range" && setOpenDate(true);
                                                val !== "Custom Range" &&
                                                    dispatch(setIsCustomeRange(false));
                                                onSetSearchDate();
                                            }}
                                        >
                                            <span className="text-xs font-semibold">{val}</span>
                                        </div>
                                    );
                                })}
                            </div>
                        </div>
                        <div className="w-[120px]">
                            <Options />
                        </div>
                        <div>
                            <CheckBox
                                label="Show Deleted Faxes"
                                value={hide_deleted}
                                checked={hide_deleted === "no" ? true : false}
                                onChange={() => {
                                    if (hide_deleted === "yes") {
                                        setFilter({
                                            ...filter,
                                            page: "1",
                                            per_page: "10",
                                            hide_deleted: "no",
                                        });
                                    } else {
                                        setFilter({
                                            ...filter,
                                            page: "1",
                                            per_page: "10",
                                            hide_deleted: "yes",
                                        });
                                    }
                                    dispatch(setDeleteHide(hide_deleted === "no" ? "yes" : "no"));
                                }}
                            />
                        </div>
                    </div>
                    <div className="flex items-center gap-2 smd:justify-end">
                        <div className="border-dark-700 border px-1.5 py-0.5 rounded-md cursor-pointer flex items-center">
                            <div
                                className={`${search ? "border-r border-dark-700 pr-1.5" : ""}`}
                            >
                                <FontAwesomeIcon
                                    className="cursor-pointer"
                                    color="#8083A3"
                                    icon={faSearch}
                                    onClick={() => {
                                        if (search) {
                                            setFilter({
                                                ...filter,
                                                search_query: "",
                                            });
                                        }
                                        setSearch(!search);
                                    }}
                                />
                            </div>
                            <Input
                                name="search"
                                isShowLabel={false}
                                value={filter.search_query}
                                placeholder="Search by name, phone and extension number"
                                className={`${!search && "hidden"
                                    } transition ease-in-out delay-1000 resize-none rounded-md w-[275px] !py-[5px] focus:outline-none !border-none`}
                                onChange={(e: any) => {
                                    setFilter({
                                        ...filter,
                                        search_query: e.target.value,
                                    });
                                }}
                            />
                        </div>
                        <Button
                            text="Compose a fax"
                            icon="add-white"
                            onClick={() => {
                                if (inCount + sentCount >= userDetails?.license_page_limit) {
                                    setIsWarn(true);
                                } else {
                                    navigation("/fax/compose-fax");
                                }
                            }}
                        />
                    </div>
                </div>
                <Table
                    isLoading={isLoading}
                    columns={columns}
                    data={sentFaxList?.faxes?.length ? sentFaxList?.faxes : []}
                    totalCount={sentFaxList?.total_faxes || 0}
                    page={filter?.page}
                    limit={filter?.per_page}
                    onPageClick={(page: number) => {
                        setFilter({
                            ...filter,
                            page,
                        });
                    }}
                />
            </div>
        </>
    );
};

export default SentFax;
