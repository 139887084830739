import { faGear, faSignOut } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

// TYPES
interface LogoutPopupProps {
    visible: boolean;
    className?: string;
    onLogoutClick?: any;
    onProfileClick?: any;
    onCancelClick?: any;
    onMigrationClick?: any;
}

/* ============================== LOGOUT POPUP ============================== */

const LogoutPopup = (props: LogoutPopupProps) => {
    const { visible, className, onLogoutClick, onCancelClick, onMigrationClick } =
        props;

    return (
        <>
            <div
                className={`fixed top-0 left-0 right-0 z-50 px-4 overflow-x-hidden overflow-y-auto md:inset-0 h-[100%] max-h-full bg-transparent ${!visible && "hidden"
                    }`}
                onClick={onCancelClick}
            >
                <div className="w-full h-full relative flex justify-center">
                    <div
                        onClick={(e) => e.stopPropagation()}
                        className={`${className} w-[180px] bg-white rounded-lg p-3 drop-shadow-xl ${!visible && "hidden"
                            }`}
                    >
                        <div
                            className="flex cursor-pointer items-center hover:text-primary gap-4 pt-2 pb-3"
                            onClick={onMigrationClick}
                        >
                            <FontAwesomeIcon color="#474747" icon={faGear} size="sm" />
                            <span className="text-xs font-semibold">Migrate Account</span>
                        </div>
                        <hr />
                        <div
                            className="flex cursor-pointer items-center hover:text-primary gap-4 pt-3 pb-2"
                            onClick={onLogoutClick}
                        >
                            <FontAwesomeIcon color="#474747" icon={faSignOut} size="sm" />
                            <span className="text-xs font-semibold">Sign out</span>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default LogoutPopup;
