import React from "react";

import {
    faBackwardStep,
    faChevronLeft,
    faChevronRight,
    faForwardStep,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { pdfjs, Document, Page } from "react-pdf";
import "react-pdf/dist/esm/Page/AnnotationLayer.css";
import "react-pdf/dist/esm/Page/TextLayer.css";

pdfjs.GlobalWorkerOptions.workerSrc = new URL(
    "pdfjs-dist/build/pdf.worker.min.js",
    import.meta.url
).toString();

interface PdfViewerProps {
    selectFile: any;
    className?: string;
}

const PdfViewer = (props: PdfViewerProps) => {
    const { selectFile, className } = props;

    const [numPages, setNumPages] = React.useState<number>(0);
    const [page, setPage] = React.useState<number>(1);
    const [fileNumber, setFileNumber] = React.useState<number>(0);

    const onDocumentLoadSuccess = async ({ numPages: nextNumPages }: any) => {
        setNumPages(nextNumPages);
    };

    React.useEffect(() => {
        setFileNumber(0);
        setPage(1);
    }, [selectFile]);

    return (
        <div className={`${className} select-none`}>
            <Document
                file={selectFile[fileNumber]}
                onLoadSuccess={onDocumentLoadSuccess}
            >
                <Page pageNumber={page} />
            </Document>
            <div className="flex justify-center items-center pt-5 select-none">
                <div className="flex items-center gap-3">
                    <FontAwesomeIcon
                        className={`cursor-pointer px-2.5 py-1.5 border-[#ECEEF5] border rounded-md ${fileNumber === 0 && "opacity-40 pointer-events-none"
                            }`}
                        color="#474747"
                        icon={faBackwardStep}
                        onClick={() => {
                            fileNumber > 0 && setFileNumber(fileNumber - 1);
                            setPage(1);
                        }}
                        title="Previous file"
                    />
                    <FontAwesomeIcon
                        className={`cursor-pointer px-2.5 py-1.5 border-[#ECEEF5] border rounded-md ${page === 1 && "opacity-40 pointer-events-none"
                            }`}
                        color="#474747"
                        icon={faChevronLeft}
                        onClick={() => page > 1 && setPage(page - 1)}
                        title="Previous page"
                    />
                    <div className="flex gap-2 items-center">
                        <label className="px-2.5 py-1 rounded-md text-heading font-normal text-sm border-[#ECEEF5] border">
                            {page}
                        </label>
                        <label className="rounded-md text-heading font-bold text-sm">
                            of&nbsp;&nbsp;{numPages}
                        </label>
                    </div>
                    <FontAwesomeIcon
                        className={`cursor-pointer px-2.5 py-1.5 border-[#ECEEF5] border rounded-md ${page === numPages && "opacity-40 pointer-events-none"
                            }`}
                        color="#474747"
                        icon={faChevronRight}
                        title="Next page"
                        onClick={() => page < numPages && setPage(page + 1)}
                    />
                    <FontAwesomeIcon
                        className={`cursor-pointer px-2.5 py-1.5 border-[#ECEEF5] border rounded-md ${fileNumber === selectFile.length - 1 &&
                            "opacity-40 pointer-events-none"
                            }`}
                        color="#474747"
                        icon={faForwardStep}
                        onClick={() => {
                            fileNumber < selectFile.length - 1 &&
                                setFileNumber(fileNumber + 1);
                            setPage(1);
                        }}
                        title="Next file"
                    />
                </div>
            </div>
        </div>
    );
};

export default PdfViewer;
