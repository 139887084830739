import { useEffect, useState } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import LogoSection from "../Sidebar/logoSection";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBars, faChevronDown } from "@fortawesome/free-solid-svg-icons";
import LogoutPopup from "components/ui-component/popup/LogoutPopup";
import { useAppDispatch } from "redux/hooks";
import { clearSlice, getUserInfo, getUserNumber } from "redux/slice/faxSlice";
import MigrationModal from "components/ui-component/modals/MigrationModal";
import {
  clearCommonSlice,
  setErrorPageData,
  setIsErrorPage,
  setLoading,
  setNumber,
  setSidebar,
  setUserInfo,
  // useAccessToken,
  useIsUserInfo,
  useSidebarOpen,
} from "redux/slice/commanSlice";
import {
  // Danger,
  Success,
} from "components/Toast/toasterService";
import {
  MigrationFormModal,
  SignOutModal,
} from "components/ui-component/modals";
import { useNavigate } from "react-router-dom";
// import { baseUrl } from "API/baseURL";

export default function Header() {
  const { user, logout } = useAuth0();
  const dispatch = useAppDispatch();
  const isUserInfo = useIsUserInfo();
  const sidebarOpen = useSidebarOpen();

  const navigation = useNavigate();
  const [showLogoutPopup, setShowLogoutPopup] = useState<boolean>(false);
  const [showLogoutModal, setShowLogoutModal] = useState<boolean>(false);
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [isOpenModal, setIsOpenModal] = useState<boolean>(false);
  // const accessToken = useAccessToken();

  useEffect(() => {
    if (isUserInfo && localStorage.getItem("isInfo") !== "1") {
      onGetUserInfo();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isUserInfo]);

  const onGetUserNumber = async (number: string) => {
    try {
      dispatch(setNumber(number));
      await dispatch(getUserNumber(number)).unwrap();
    } catch (error: any) {
      console.log("Get USer number Err: --->", error?.message);
    }
  };

  const onGetUserInfo = async () => {
    try {
      localStorage.setItem("isInfo", "1");
      const username = user?.email;
      if (username !== undefined) {
        const res: any = await dispatch(getUserInfo(username)).unwrap();
        if (res && res.assigned_number?.length) {
          onGetUserNumber(res.assigned_number?.[0]);
        }
        // linkAccount();
        if (res && res.ask_migration === "yes") {
          setIsOpen(true);
        } else if (res.error === "invalid user") {
          dispatch(setErrorPageData(res));
          dispatch(setIsErrorPage(true));
          navigation("/invalid-user");
          dispatch(setLoading(false));
        }
        dispatch(setUserInfo(false));
        dispatch(setLoading(false));
      } else {
        dispatch(setUserInfo(false));
        dispatch(setLoading(false));
      }
    } catch (error: any) {
      dispatch(setIsErrorPage(true));
      navigation("/invalid-user");
      dispatch(setLoading(false));
      console.log("Get USer Info Err: --->", error?.message);
    }
  };

  // const linkAccount = async () => {
  //   // authenticateUser should authenticate the user with the account to be linked
  //   // See the Github sample for more details

  //   if (!user?.email_verified) {
  //     Danger(
  //       `Account linking is only allowed to a verified account. Please verify your email ${user?.email}.`
  //     );
  //   }
  //   await fetch(`${baseUrl}/users/${user?.sub}/identities`, {
  //     method: "POST",
  //     headers: {
  //       "Content-Type": "application/json",
  //       Authorization: `Bearer ${accessToken}`,
  //     },
  //     body: JSON.stringify({
  //       link_with: user?.targetUserIdToken,
  //     }),
  //   });
  // };

  return (
    <>
      <div className="fixed flex justify-between pr-4 h-[55px] bg-white w-full z-20 border-dark-700 transition-transform drop-shadow-md">
        <div className="flex items-center">
          <LogoSection />
          <FontAwesomeIcon
            icon={faBars}
            color="black"
            cursor="pointer"
            className="smd:block hidden"
            onClick={() => dispatch(setSidebar(!sidebarOpen))}
          />
        </div>
        <div className="flex items-center flex-row">
          <div className="rounded-full mr-2 relative cursor-pointer">
            <div className="3xl:h-9 3xl:w-9 h-7 w-7 bg-[#b4cff98f] rounded-full flex items-center justify-center">
              <span className="3xl:text-base text-sm text-heading font-bold">
                {/* {user?.name?.slice(0, 1).toUpperCase()} */}
                <img
                  src={user?.picture}
                  alt="profile"
                  className="rounded-full"
                />
              </span>
            </div>
            <div className="bg-white h-2.5 w-2.5 rounded-full absolute flex justify-center items-center right-0 bottom-0">
              <div className="bg-primary-green h-1.5 w-1.5 rounded-full"></div>
            </div>
          </div>
          <div className="flex flex-col cursor-pointer">
            <div>
              <p className="text-xs font-semibold text-heading">{user?.name}</p>
            </div>
            <div className="flex items-center">
              <p className="text-[10px] font-normal text-txt-primary mr-1.5">
                {user?.email}
              </p>
            </div>
          </div>
          <FontAwesomeIcon
            className="cursor-pointer"
            color="#474747"
            icon={faChevronDown}
            onClick={() => {
              setShowLogoutPopup(true);
            }}
            size="xs"
          />
        </div>
      </div>
      <LogoutPopup
        visible={showLogoutPopup}
        className="z-10 absolute top-[58px] right-0"
        onCancelClick={() => setShowLogoutPopup(false)}
        onMigrationClick={() => {
          setIsOpen(true);
          setShowLogoutPopup(false);
        }}
        onLogoutClick={() => {
          setShowLogoutPopup(false);
          setShowLogoutModal(true);
        }}
      />
      <MigrationModal
        visible={isOpen}
        onDoneClick={() => {
          setIsOpen(false);
          setIsOpenModal(true);
        }}
        onCancelClick={() => setIsOpen(false)}
      />
      <MigrationFormModal
        visible={isOpenModal}
        onCancelClick={() => {
          setIsOpenModal(false);
        }}
      />
      <SignOutModal
        visible={showLogoutModal}
        onCancelClick={() => setShowLogoutModal(false)}
        onDoneClick={() => {
          logout();
          localStorage.clear();
          Success("Sign out successfully");
          setShowLogoutModal(false);
          dispatch(clearSlice());
          dispatch(clearCommonSlice());
        }}
      />
    </>
  );
}
