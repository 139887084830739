import { IconProp, SizeProp } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

// TYPES
interface InputProps {
    className?: string;
    label?: string;
    type?: string;
    name: string;
    value?: string | number;
    placeholder?: string;
    icon?: IconProp;
    tooltipRight?: boolean;
    isInfo?: boolean;
    noSpace?: boolean;
    isShowLabel?: boolean;
    tooltip?: string;
    rightTooltip?: string;
    rightIcon?: string;
    touched?: any;
    errors?: any;
    onChange?: any;
    disabled?: boolean;
    onFocus?: any;
    onBlur?: any;
    onIconClick?: any;
    onRightIconClick?: any;
    color?: string;
    size?: SizeProp;
    readOnly?: boolean;
}

/* ============================== INPUT ============================== */

const Input = (props: InputProps) => {
    const {
        className,
        label,
        type = "text",
        name,
        value,
        placeholder,
        disabled,
        icon,
        isShowLabel = true,
        rightIcon,
        noSpace = false,
        touched,
        errors,
        onChange,
        onFocus,
        onBlur,
        color = "white",
        size = "xl",
        readOnly = false,
    } = props;

    return (
        <div>
            {isShowLabel && (
                <div className="text-xs font-semibold pb-1.5 flex items-center">
                    <p>{label ? label : name}</p>
                </div>
            )}
            <div className="flex flex-row items-center relative">
                {icon && (
                    <FontAwesomeIcon
                        className="cursor-pointer"
                        color={color}
                        icon={icon}
                        size={size}
                    />
                )}
                <input
                    readOnly={readOnly}
                    className={`focus:outline-none border-2 text-xs placeholder:text-txt-secondary rounded-md focus:border-heading py-2 w-full ${icon ? "pl-8" : "pl-3"
                        } ${rightIcon ? "pr-8" : "pr-3"} ${touched && errors && touched[name] && errors[name]
                            ? "border-error"
                            : "border-dark-700"
                        } ${className}`}
                    type={type}
                    name={name}
                    placeholder={placeholder}
                    value={value}
                    onChange={(e: any) => {
                        onChange &&
                            onChange({
                                target: {
                                    value: noSpace
                                        ? e.target.value?.replace(/\s/g, "")?.toString()
                                        : e.target.value?.toString(),
                                    name,
                                    id: e.target?.id,
                                },
                            });
                    }}
                    disabled={disabled}
                    onBlur={onBlur}
                    onFocus={onFocus}
                />
            </div>
            {touched && errors && touched[name] && errors[name] && (
                <span className="text-error font-normal text-[10px]">
                    {errors[name]}
                </span>
            )}
        </div>
    );
};

export default Input;
