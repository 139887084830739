// THIRD-PARTY IMPORT IMPORTS
import { toast } from "react-toastify";

/* ============================== TOSTER SERVICES ============================== */

// SUCCESS TOSTER

export function Success(msg: string) {
  toast.success(
    <>
      <p className="3xl:text-base text-sm font-bold">Success</p>
      <p className="3xl:text-sm text-xs mb-0">{msg}</p>
    </>,
    {
      position: toast.POSITION.TOP_RIGHT,
      hideProgressBar: false,
      icon: false,
      autoClose: 3000,
      theme: "colored",
    }
  );
}

// ERROR TOSTER

export function Danger(msg: string) {
  toast.error(
    <>
      <p className="3xl:text-base text-sm font-bold">Error</p>
      <p className="3xl:text-sm text-xs mb-0">
        {msg ? msg : "Something Went Wrong!"}
      </p>
    </>,
    {
      position: toast.POSITION.TOP_RIGHT,
      hideProgressBar: false,
      icon: false,
      autoClose: 3000,
      theme: "colored",
    }
  );
}
