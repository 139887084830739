export const tableCustomStyles = {
  headRow: {
    style: {
      color: "black",
      backgroundColor: "#e2f6ff",
      fontWeight: 700,
      fontSize: "12px",
      minHeight: "40px",
      borderRadius: "8px 8px 0 0",
    },
  },
  rows: {
    style: {
      color: "black",
      backgroundColor: "#f8fcff",
      minHeight: "40px",
    },
    stripedStyle: {
      color: "black",
      backgroundColor: "white",
      minHeight: "40px",
    },
  },
  table: {
    style: {
      height: "calc(100vh - 210px)",
      backgroundColor: "white",
      border: "1px solid #EDEDED",
      borderRadius: "8px 8px 0 0",
      "@media (min-width: 0px) and (max-width: 820px)": {
        height: "calc(100vh - 265px)",
      },
    },
  },
  pagination: {
    style: {
      borderRadius: "0 0 8px 8px",
      border: "1px solid #EDEDED",
      minHeight: "30px",
    },
  },
};

export const tableCustomStyle = {
  table: {
    style: {
      height: "calc(100vh - 210px)",
      backgroundColor: "white",
      border: "none",
      "@media (min-width: 0px) and (max-width: 820px)": {
        height: "calc(100vh - 265px)",
      },
    },
  },
};
