import ConfirmationModal from "./ConfirmationModal";

import download from "assets/icons/download.svg";
import trash from "assets/icons/trash.svg";
import edit from "assets/icons/edit.svg";
import { useState } from "react";
import { Textarea } from "../forms";
import { useAppDispatch } from "redux/hooks";
import { downloadFax, updateFax } from "redux/slice/faxSlice";
import { DownloadFile } from "components/Helper/DownloadFile";
import { Danger, Success } from "components/Toast/toasterService";

interface EditSentFaxModalProps {
    visible: boolean;
    isLoading?: boolean;
    onCancelClick?: any;
    data: any;
    setSentFaxData?: any;
    setDeleteId?: any;
}

/* ============================== DELETE MODEL ============================== */

const EditSentFaxModal = (props: EditSentFaxModalProps) => {
    const {
        visible = false,
        isLoading = false,
        onCancelClick,
        data,
        setSentFaxData,
        setDeleteId,
    } = props;

    const [isEdit, setIsEdit] = useState<boolean>(false);
    const [note, setNote] = useState<string>("");

    const dispatch = useAppDispatch();

    const onSubmit = async () => {
        try {
            let payload = {
                id: data?.uuid,
                notes: note,
                read: "yes",
            };
            await dispatch(updateFax(payload)).unwrap();
            onCancelClick();
            setNote("");
            setIsEdit(false);
            Success("Fax Edited successfully");
        } catch (error: any) {
            Danger("Fax can't be edited");
            console.log("Update fax err", error?.message);
        }
    };

    const onDownloadFax = async (id: string) => {
        try {
            const res: any = await dispatch(downloadFax(id)).unwrap();
            DownloadFile(res, id);
        } catch (error: any) {
            console.log("fax download err: -->", error?.message);
        }
    };

    return (
        <>
            <ConfirmationModal
                className="!max-w-[600px] !top-[calc(50%-230px)]"
                isLoading={isLoading}
                visible={visible}
                onCancelClick={() => {
                    setNote("");
                    setIsEdit(false);
                    onCancelClick();
                }}
                onDoneClick={() => onSubmit()}
                cancel={false}
                doneText="Ok"
            >
                <div className="w-full pb-4">
                    <div className="flex justify-between items-center pb-4">
                        <label className="text-lg font-bold text-heading">
                            Fax Details
                        </label>
                        <div className="flex gap-2">
                            <div
                                className="bg-primary-v10 p-2 border-primary-v8 rounded-lg cursor-pointer border"
                                onClick={() => {
                                    onDownloadFax(data?.uuid);
                                }}
                            >
                                <img src={download} alt="download" />
                            </div>
                            <div
                                className="bg-primary-v10 p-2 border-primary-v8 rounded-lg cursor-pointer border"
                                onClick={() => {
                                    setDeleteId(data?.uuid);
                                    setSentFaxData(null);
                                }}
                            >
                                <img src={trash} alt="trash" />
                            </div>
                        </div>
                    </div>
                    <div className="p-4 bg-primary-v10 border border-primary-v8 rounded-lg">
                        <div className="grid grid-cols-3 smd:grid-cols-1 gap-4">
                            {/* <div className="flex flex-col items-start">
                                <label className="text-sm font-semibold text-heading">
                                    Sender Name
                                </label>
                                <label className="text-sm text-secondary">
                                    {data?.from_name || ""}
                                </label>
                            </div> */}
                            <div className="flex flex-col items-start">
                                <label className="text-sm font-semibold text-heading">
                                    Sender Fax Number
                                </label>
                                <label className="text-sm text-secondary">
                                    {data?.from_number || ""}
                                </label>
                            </div>
                            <div className="flex flex-col items-start">
                                <label className="text-sm font-semibold text-heading">
                                    Date
                                </label>
                                <label className="text-sm text-secondary">
                                    {data?.created_on || ""}
                                </label>
                            </div>
                            {/* <div className="flex flex-col items-start">
                                <label className="text-sm font-semibold text-heading">
                                    Recipient Name
                                </label>
                                <label className="text-sm text-secondary">
                                    {data?.to_name || ""}
                                </label>
                            </div> */}
                            <div className="flex flex-col items-start">
                                <label className="text-sm font-semibold text-heading">
                                    Recipient Fax Number
                                </label>
                                <label className="text-sm text-secondary">
                                    {data?.to_number || ""}
                                </label>
                            </div>
                            <div className="flex flex-col items-start">
                                <label className="text-sm font-semibold text-heading">
                                    No. of Pages
                                </label>
                                <label className="text-sm text-secondary">
                                    {data?.pages || 0}
                                </label>
                            </div>
                            {/* <div className="flex flex-col items-start">
                                <label className="text-sm font-semibold text-heading">
                                    Attachment
                                </label>
                                <label className="text-sm text-primary cursor-pointer">
                                    Preview Attachment
                                </label>
                            </div> */}
                            <div className="flex flex-col items-start">
                                <label className="text-sm font-semibold text-heading">
                                    Status
                                </label>
                                <label
                                    className={`${data?.status === "sending"
                                            ? "text-primary"
                                            : data?.status === "delivered"
                                                ? "text-primary-green"
                                                : data?.status === "deleted"
                                                    ? "text-layout"
                                                    : "text-[#FF455C]"
                                        } text-sm`}
                                >
                                    {data?.status || ""}
                                </label>
                            </div>
                        </div>
                    </div>
                    <div className="pt-4 px-2">
                        <div className="flex justify-between items-center">
                            <label className="text-base font-bold text-heading">Notes</label>
                            <div
                                className="cursor-pointer"
                                onClick={() => {
                                    !isEdit && setNote(data?.notes || "");
                                    setIsEdit(!isEdit);
                                }}
                            >
                                <img src={edit} alt="edit" width="18px" height="18px" />
                            </div>
                        </div>
                        {isEdit ? (
                            <div>
                                <Textarea
                                    name=""
                                    placeholder="Enter a note"
                                    value={note}
                                    onChange={(e: any) => setNote(e.target.value)}
                                />
                            </div>
                        ) : (
                            <p className="pt-2 text-secondary text-xs">{data?.notes}</p>
                        )}
                    </div>
                </div>
            </ConfirmationModal>
        </>
    );
};

export default EditSentFaxModal;
