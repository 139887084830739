export const FAXES = "/v2/fax/get_faxes";
export const DELETE_FAXES = "/v2/fax/delete";
export const DOWNLOAD_FAXES = "/v2/fax/download";
export const UPDATE_FAXES = "/v2/fax/update";
export const SEND_FAX = "/v2/fax/send_fax";
export const RESEND_FAX = "/v2/fax/resend_fax";

export const GET_INFO = "/v2/fax/get_ui";
export const USER_MIGRATION = "/v2/fax/migrate_account";
export const GET_NUMBER = "/v2/fax/get_number";
