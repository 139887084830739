import { Route, Routes, useLocation, useNavigate } from "react-router-dom";
import Header from "../Header";
import Dashboard from "pages/dashboard";
import Fax from "pages/fax";
import ComposeFax from "pages/fax/composeFax";
import { useAuth0 } from "@auth0/auth0-react";
import Loader from "components/ui-component/loader/Loader";
import { useAppDispatch } from "redux/hooks";
import {
    clearCommonSlice,
    setAccessToken,
    setLoading,
    setUserInfo,
    useAccessToken,
    useIsError,
    useLoading,
} from "redux/slice/commanSlice";
import { useEffect } from "react";
import InfoError from "pages/infoError";
import { clearSlice } from "redux/slice/faxSlice";

const MainLayout = () => {
    const {
        user,
        isAuthenticated,
        isLoading,
        loginWithRedirect,
        logout,
        getAccessTokenSilently,
    } = useAuth0();

    const dispatch = useAppDispatch();

    const accessToken = useAccessToken();
    const location = useLocation();
    const pathName = location.pathname;
    const isError = useIsError();
    const loading = useLoading();

    const navigation = useNavigate();

    const AuthHeader = async () => {
        try {
            const audience = process.env.REACT_APP_AUTH0_AUDIENCE;
            const accessToken: any = await getAccessTokenSilently({
                authorizationParams: {
                    audience: audience,
                    scope: "read:current_user",
                },
            });
            dispatch(setAccessToken(accessToken));
            localStorage.setItem("accessToken", accessToken);
        } catch (error: any) {
            if (error.message === "Login required" && !isAuthenticated) {
                loginWithRedirect();
                dispatch(setUserInfo(true));
            } else if (
                error?.message === "We could not locate your OneCloud Account"
            ) {
                logout();
                localStorage.clear();
                dispatch(clearSlice());
                dispatch(clearCommonSlice());
            } else {
                logout();
                localStorage.clear();
                dispatch(clearSlice());
                dispatch(clearCommonSlice());
            }
        }
    };

    useEffect(() => {
        AuthHeader();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [getAccessTokenSilently, user?.sub, isAuthenticated]);

    useEffect(() => {
        if (isError) {
            navigation("/invalid-user");
        } else if (pathName === "/invalid-user") {
            navigation("/");
        } else if (accessToken) {
            dispatch(setLoading(false));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isError, pathName]);

    if (isLoading || !!!accessToken)
        return (
            <div className="h-[100vh]">
                <Loader />
            </div>
        );

    return (
        <>
            {isAuthenticated && !!accessToken ? (
                <>
                    <Header />
                    {/* <Sidebar /> */}
                    {loading ? (
                        <div className="h-[100vh]">
                            <Loader />
                        </div>
                    ) : (
                        <div className="pt-[55px] pr-[10px] min-h-[100vh] overflow-auto">
                            <Routes>
                                <Route path="/invalid-user" element={<InfoError />} />
                                <Route path="/dashboard" element={<Dashboard />} />
                                <Route path="/" element={<Fax />} />
                                <Route path="/fax" element={<Fax />} />
                                <Route path="/fax/compose-fax" element={<ComposeFax />} />
                            </Routes>
                        </div>
                    )}
                </>
            ) : null}
        </>
    );
};
export default MainLayout;
